import { gql } from '@apollo/client';
import { env, Fragments } from 'common';

/**
 * This query retrieves the sorted topics IDs, so it can be used to sort the topics on the client.
 * The sort order of the logged in user will be different from the anonymous user, that's why we need this query.
 */
export const TopicSortOrder = gql`
	query TopicSortOrder($orderBy: [String!]) {
		topicDocuments(
			searchQuery: {
				filters: [
					{ field: "level", value: ["0", "1"] }
					{ field: "activated", value: "true" }
				]
				orders: $orderBy
			}
		) {
			data {
				id
			}
		}
	}
`;

/**
 * This query retrieves the user speciality from backend through retrieving the first topic of the list. This is possible because backend is sorting the topics by the user's speciality. If the user is anonymous, the topic code returned will be AM.
 *
 * There doesn't seem to be a mapping between the user's speciality (in the user service) and the topic codes, at least no mapping that's exposed to the Frontend, so we have to use this workaround at the moment.
 */
export const CurrentUserSpeciality = gql`
	query CurrentUserSpeciality {
		topicDocuments(searchQuery: { pageSize: 1 }) {
			data {
				id
				code
			}
		}
	}
`;

/**
 * This query retrieves the list of topics used on the Guidelines page.
 */
export const TopicsForSelector = gql`
	query TopicsForSelector {
		topicDocuments(
			searchQuery: {
				filters: [
					{ field: "level", value: ["0", "1"] }
					{ field: "activated", value: "true" }
				]
			}
		) {
			data {
				id
				code
				description
				iconPrimaryColor
				abbreviation
				specialityIconUrl
			}
		}
	}
`;

/**
 * This query retrieves the list of Guidelines used on the Guidelines page.
 */
export const AllGuidelinesForGuidelinesPage = gql`
	query AllGuidelinesForGuidelinesPage($searchQuery: ContentSearchInput!) {
		contentDocuments(searchQuery: $searchQuery) {
			data {
				id
				brand
				startDateTime
				topics {
					id
					code
					level
				}
				source {
					url
				}
				title
			}
		}
	}
`;

export const SearchForTopics = gql`
	${Fragments.TopicFields}
	${Fragments.AggregationFields}
	query SearchForTopics($searchQuery: SearchQuery!) {
		topicDocuments(searchQuery: $searchQuery) {
			data {
				...TopicFields
			}
			totalCount
			pageNumber
			pageSize
			aggregations {
				...AggregationFields
			}
		}
	}
`;

export const ContentDocumentsSimplified = gql`
	query ContentDocumentsSimplified($searchQuery: ContentSearchInput!) {
		contentDocuments(searchQuery: $searchQuery) {
			data {
				id
			}
			totalCount
		}
	}
`;

export const ContentDocuments = gql`
	${Fragments.ContentCreditFields}
	${Fragments.SpeakerFields}
	${Fragments.TopicFields}
	${Fragments.SourceFields}
	${Fragments._ProductDocumentFields}
	query ContentDocuments($searchQuery: ContentSearchInput!) {
		contentDocuments(searchQuery: $searchQuery) {
			data {
				__typename
				id
				brand
				endDateTime
				externalId
				contentId
				contentType
				source {
					...SourceFields
				}
				startDateTime
				willBeBroadcasted
				isFree
				isAvailableOnHomepage
				participationOption
				capacityStatus
				locale
				title
				city
				subtitle
				slug
				provider {
					name
					id
				}
				credits {
					...ContentCreditFields
				}
				speaker {
					...SpeakerFields
				}
				scientificLeaders {
					...SpeakerFields
				}
				topics {
					...TopicFields
				}
				parent {
					locale
					isFree
					contentId
					contentType
				}
				product {
					..._ProductDocumentFields
				}
				specialities
			}
			totalCount
		}
	}
`;

export const Products = gql`
	${Fragments.ProductFields}
	query Products($searchQuery: SearchQuery!) {
		products(searchQuery: $searchQuery) {
			data {
				...ProductFields
			}
			totalCount
			pageNumber
			pageSize
		}
	}
`;

/**
 * Was used in admin-catalog for the "contents" query.
 *
 * TODO: arsinclair: need to decouple Partnerforum from it and remove the query.
 *
 * @deprecated
 */
export const Contents = gql`
	${Fragments.ContentFields}
	query Contents($searchQuery: SearchQuery!) {
		contents(searchQuery: $searchQuery) {
			data {
				...ContentFields
			}
			totalCount
			pageNumber
			pageSize
		}
	}
`;

export const VerifyContentExists = gql`
	query VerifyContentExists($searchQuery: SearchQuery!) {
		contents(searchQuery: $searchQuery) {
			data {
				id
				schedule {
					id
					source {
						url
					}
				}
			}
		}
	}
`;

export const PartnerDocuments = gql`
	${Fragments.SourceFields}
	query PartnerDocuments($searchQuery: SearchQuery!) {
		contents(searchQuery: $searchQuery) {
			data {
				id
				title
				isCommercial
				contentType
				contentId
				sponsorships {
					sponsorDepartments {
						companyPage {
							publishStatus
						}
					}
				}
				source {
					...SourceFields
				}
			}
			pageNumber
			pageSize
			totalCount
		}
	}
`;

export const Sponsorships = gql`
	${Fragments.SponsorshipFields}
	query Sponsorships($searchQuery: SearchQuery) {
		sponsorships(searchQuery: $searchQuery) {
			data {
				...SponsorshipFields
			}
			totalCount
			pageNumber
			pageSize
		}
	}
`;

/**
 * A list of sponsorships for the Partners page with minimal number of fields
 */
export const SponsorshipsList = gql`
	query SponsorshipsList($searchQuery: SearchQuery) {
		sponsorships(searchQuery: $searchQuery) {
			pageSize
			pageNumber
			totalCount
			data {
				id
				sponsorshipType
				sponsorCompanyId
				sponsorCompany {
					id
					companyPage {
						slug
						publishStatus
					}
					logoUrl
					name
					websites
				}
			}
		}
	}
`;

export const PartnerCompanies = gql`
	${Fragments.PartnerCompanySimplified}
	query PartnerCompanies($searchQuery: SearchQuery!) {
		partnerCompanies(searchQuery: $searchQuery) {
			totalCount
			data {
				...PartnerCompanySimplified
			}
		}
	}
`;

export const PartnerCompaniesWithDepartments = gql`
	query PartnerCompaniesWithDepartments($searchQuery: SearchQuery!) {
		partnerCompanies(searchQuery: $searchQuery) {
			totalCount
			data {
				id
				name
				logoUrl
				description
				slug
				country
				publishedDomains
				departments {
					id
					translatedName
					slug
				}
			}
		}
	}
`;

export const MediathekVideos = gql`
	${Fragments.MediathekVideoFields}
	query MediathekVideos($searchQuery: ContentSearchInput!) {
		contentDocuments(searchQuery: $searchQuery) {
			totalCount
			pageNumber
			pageSize
			data {
				...MediathekVideoFields
			}
		}
	}
`;

export const MediathekPartnerforumContents = gql`
	${Fragments.MediathekVideoFields}
	query MediathekPartnerforumContents($searchQuery: ContentSearchInput!) {
		contentDocuments(searchQuery: $searchQuery) {
			totalCount
			pageNumber
			pageSize
			data {
				...MediathekVideoFields
				sponsorships {
					sponsorCompany {
						id
						name
						logoUrl
						slug
						publishStatus
					}
				}
			}
		}
	}
`;

export const FeaturedCourses = gql`
	query FeaturedCourses($searchQuery: ContentSearchInput!) {
		contentDocuments(searchQuery: $searchQuery) {
			totalCount
			pageNumber
			pageSize
			data {
				__typename
				title
				dominantColor
				brand
				startDateTime
				endDateTime
				contentId
				id
				contentType
				city
				slug
				product {
					specialityIconUrl
					iconPrimaryColor
					id
				}
				credits {
					amount
					unit
					isApproved
					participationType
				}
				participationOption
			}
		}
	}
`;

export const GetCompanyPageSlug = gql`
	query GetCompanyPageSlug($id: ID!) {
		company(id: $id) {
			companyPage {
				slug
			}
		}
	}
`;

export const GetCompanyDepartments = gql`
	query GetCompanyDepartments($id: ID) {
		company(id: $id) {
			departments {
				id
				companyPage {
					slug
				}
			}
		}
	}
`;

export const GetRecentlyWatchedContents = gql`
	${Fragments.ContentDocumentSearchFields}
	query GetRecentlyWatchedContents {
		recentlyWatchedContents {
			completed
			content {
				...ContentDocumentSearchFields
				rating
			}
			contentId
			time
			trackedDateTime
			userId
		}
	}
`;

export const GetUserSpecialities = gql`
	query GetUserSpecialities($userId: ID!, $tenantId: ID = "${env.TENANT_ID}") {
		user(userId: $userId, tenantId: $tenantId) {
			specialities {
				id
				code
			}
		}
	}
`;

export const GetRecommendedContents = gql`
	query GetRecommendedContents($searchQuery: ContentSearchInput!) {
		contentDocuments(searchQuery: $searchQuery) {
			totalCount
			pageNumber
			pageSize
			data {
				title
				brand
				contentId
				id
				__typename
				contentType
				isFree
				topics {
					id
					code
					iconPrimaryColor
					parents {
						id
						code
					}
				}
				speakers {
					id
					avatarSrcUrl
					description
					gender
					name
					title
				}
				source {
					duration
					thumbnail
				}
				parent {
					id
					contentType
					contentId
					isFree
					provider {
						id
						logoUrl
					}
				}
				rating
			}
		}
	}
`;

export const GetOnDemandContentQuestionsById = gql`
	query GetOnDemandContentQuestionsById($contentId: String!) {
		course(identifier: $contentId) {
			questions {
				id
				type
				description
				order
				module {
					id
				}
				answers {
					id
					description
				}
			}
		}
	}
`;

export const OnDemandCourses = gql`
	query OnDemandCourses($searchQuery: SearchQuery!) {
		courses(searchQuery: $searchQuery) {
			data {
				brand
				contentId
				slug
				subtitle
				externalId
				startDateTime
				endDateTime
				validDate
				city
				id
				locale
				contentType
				product {
					id
					name
					code
					brand
					specialityIconUrl
					iconPrimaryColor
					topics {
						id
						code
					}
				}
				willBeBroadcasted
				participationOption
				modules {
					id
				}
				videos {
					module {
						id
					}
					source {
						duration
					}
				}
				source {
					duration
				}
				capacityStatus
				credits {
					participationType
					isApproved
					amount
					unit
				}
				specialities
				title
			}
			aggregations {
				buckets {
					key
					value
				}
				name
			}
			totalCount
		}
	}
`;

export const RecentlyStartedCourses = gql`
	${Fragments.SourceFields}
	query RecentlyStartedCourses {
		recentlyStartedCourses {
			course {
				brand
				contentId
				slug
				externalId
				startDateTime
				endDateTime
				city
				id
				locale
				contentType
				videos {
					contentProgress {
						trackedDateTime
						completed
						contentId
						time
					}
					source {
						...SourceFields
					}
					contentId
					endDateTime
					title
					endDateTime
					replacementDate
					contentType
					startDateTime
				}
				product {
					id
					name
					code
					brand
					specialityIconUrl
					iconPrimaryColor
					topics {
						id
						code
					}
				}
				willBeBroadcasted
				participationOption
				capacityStatus
				credits {
					participationType
					isApproved
					amount
					unit
				}
				specialities
				title
			}
		}
	}
`;

export const OnDemandCourseForPlayerById = gql`
	${Fragments.SpeakerFields}
	${Fragments.SourceFields}
	query OnDemandCourseForPlayerById($identifier: String!) {
		course(identifier: $identifier) {
			__typename
			contentId
			id
			slug
			subtitle
			externalId
			description
			title
			city
			isFree
			externalId
			participationOption
			courseRatingUrl
			videoRatingUrl
			brand
			startDateTime
			isAvailableOnMediathek
			endDateTime
			locale
			isFree
			isSponsored
			contentType
			headerUrl
			questions {
				id
				type
				description
				order
				module {
					id
				}
				answers {
					id
					description
				}
				passed {
					passedAt
					questionId
				}
			}
			certificate {
				reportStatus
				reportDate
				file {
					downloadUrl
					name
				}
			}
			parent {
				contentId
				contentType
				isFree
			}
			source {
				...SourceFields
			}
			provider {
				name
				id
				logoUrl
			}
			department {
				logoUrl
			}
			seoSettings {
				title
				description
				image
				indexSetting
			}
			speakers {
				...SpeakerFields
			}
			scientificLeaders {
				...SpeakerFields
			}
			videos {
				module {
					id
					order
					title
				}
				contentProgress {
					trackedDateTime
					completed
					contentId
					time
				}
				seoSettings {
					image
					indexSetting
				}
				source {
					url
					duration
					thumbnail
				}
				source {
					...SourceFields
				}
				provider {
					name
					id
					logoUrl
				}
				department {
					logoUrl
				}
				order
				id
				contentId
				endDateTime
				title
				headerUrl
				brand
				isFree
				sponsorships {
					sponsorCompany {
						id
					}
				}
				externalId
				isAvailableOnMediathek
				isSponsored
				contentType
				startDateTime
				speaker {
					...SpeakerFields
				}
				speakers {
					...SpeakerFields
				}
				rating
				replacementDate
				questions {
					id
					type
					description
					order
					answers {
						id
						description
					}
					passed {
						passedAt
						questionId
					}
				}
			}
			credits {
				isApproved
				participationType
				unit
				amount
			}
			accreditationSettings {
				description
				showScheme
			}
			agendaSettings {
				showAgenda
				showSpeakers
				defaultDescription
			}
			programSchedules {
				id
				title
				startDateTime
				endDateTime
				level
				scheduleType
				speakers {
					...SpeakerFields
				}
				provider {
					name
				}
			}
			product {
				name
				code
				specialityIconUrl
			}
			modules {
				id
				order
				credits {
					participationType
					isApproved
					amount
					unit
				}
				title
				certificate {
					moduleId
					reportDate
					reportStatus
					requiredCreditAmount
					userId
					file {
						downloadUrl
						id
						name
						url
					}
				}
				functionId
			}
		}
	}
`;

export const OnDemandCourseById = gql`
	${Fragments.SpeakerFields}
	query OnDemandCourseById($identifier: String!) {
		course(identifier: $identifier) {
			__typename
			contentId
			id
			slug
			subtitle
			externalId
			description
			descriptionTitle
			title
			certificate {
				reportStatus
				reportDate
				file {
					downloadUrl
					name
				}
			}
			courseRatingUrl
			videoRatingUrl
			city
			participationOption
			brand
			startDateTime
			endDateTime
			locale
			validDate
			contentType
			seoSettings {
				title
				description
				image
				indexSetting
			}
			scientificLeaders {
				...SpeakerFields
			}
			detailsPageSettings {
				eventBookable
			}
			videos {
				contentType
				contentProgress {
					trackedDateTime
					completed
					contentId
					time
				}
				source {
					url
					duration
					thumbnail
				}
				module {
					id
					title
				}
				product {
					specialityIconUrl
				}
				questions {
					id
					type
					description
					order
					answers {
						id
						description
					}
					passed {
						passedAt
						questionId
					}
				}
				id
				title
				contentId
				endDateTime
				order
				startDateTime
				replacementDate
				speakers {
					...SpeakerFields
				}
			}
			credits {
				isApproved
				participationType
				unit
				amount
			}
			accreditationSettings {
				description
				showScheme
				showSection
			}
			agendaSettings {
				showAgenda
				showSpeakers
				defaultDescription
			}
			programSchedules {
				id
				title
				startDateTime
				endDateTime
				level
				scheduleType
				speakers {
					...SpeakerFields
				}
				provider {
					name
				}
			}
			product {
				name
				code
				specialityIconUrl
			}
			modules {
				id
				order
				title
				credits {
					amount
					unit
					isApproved
					participationType
					accreditationInstitute
				}
				certificate {
					moduleId
					reportDate
					reportStatus
					requiredCreditAmount
					userId
					file {
						downloadUrl
						id
						name
						url
					}
				}
				functionId
			}
		}
	}
`;

export const EventPriceList = gql`
	${Fragments.PriceListFields}
	query EventPriceList($ubEventId: String!, $tenantId: ID = "${env.TENANT_ID}") {
		eventPriceList(ubEventId: $ubEventId, tenantId: $tenantId) {
			...PriceListFields
		}
	}
`;

export const ContentDocumentsExtended = gql`
	${Fragments.ContentCreditFields}
	${Fragments.SpeakerFields}
	${Fragments.TopicFields}
	${Fragments.SourceFields}
	${Fragments._ProductDocumentFields}
	query ContentDocumentsExtended($searchQuery: ContentSearchInput!) {
		contentDocuments(searchQuery: $searchQuery) {
			data {
				__typename
				id
				brand
				endDateTime
				externalId
				contentId
				contentType
				source {
					...SourceFields
				}
				startDateTime
				willBeBroadcasted
				isFree
				isAvailableOnHomepage
				participationOption
				capacityStatus
				locale
				title
				city
				subtitle
				slug
				provider {
					name
					id
				}
				credits {
					...ContentCreditFields
				}
				speaker {
					...SpeakerFields
				}
				scientificLeaders {
					...SpeakerFields
				}
				topics {
					...TopicFields
				}
				parent {
					locale
					isFree
					contentId
					contentType
				}
				product {
					..._ProductDocumentFields
				}
				specialities
			}
			totalCount
		}
	}
`;

export const ContentsWithProgramSchedules = gql`
	${Fragments.ContentWithProgramSchedulesFields}
	query ContentsWithProgramSchedules($searchQuery: SearchQuery!) {
		contents(searchQuery: $searchQuery) {
			data {
				...ContentWithProgramSchedulesFields
			}
			totalCount
			pageNumber
			pageSize
		}
	}
`;

export const PartnerForumAllCompanyVideos = gql`
	query PartnerForumAllCompanyVideos($searchQuery: SearchQuery!) {
		contents(searchQuery: $searchQuery) {
			data {
				id
				contentId
				contentType
				replacementDate
				brand
				headerUrl
				startDateTime
				endDateTime
				title
				isFree
				isCommercial
				order
				rating
				contentUnit {
					order
					title
					id
				}
				speakers {
					id
					title
					name
					avatarSrcUrl
					description
				}
				source {
					duration
					file {
						downloadUrl
						name
					}
					url
					thumbnail
				}
				product {
					name
					code
					specialityIconUrl
				}
				provider {
					id
					name
					logoUrl
				}
				department {
					logoUrl
				}
				contentProgress {
					completed
					time
				}
				isSponsored
				isAvailableOnMediathek
				isAvailableWithoutEfn
				sponsorships {
					participantRestriction
					sponsorCompany {
						id
						name
						logoUrl
					}
					sponsorDepartments {
						id
						name
						logoUrl
					}
				}
				parent {
					isFree
					sponsorships {
						sponsorCompany {
							id
							name
							logoUrl
						}
						sponsorDepartments {
							id
							name
							logoUrl
						}
					}
				}
			}
			totalCount
			pageNumber
			pageSize
		}
	}
`;

export const PartnerForumOverviewVideos = gql`
	query PartnerForumOverviewVideos($searchQuery: SearchQuery!) {
		contents(searchQuery: $searchQuery) {
			data {
				id
				contentType
				contentId
				brand
				startDateTime
				title
				isCommercial
				rating
				parent {
					title
					brand
					city
					contentType
					startDateTime
				}
				source {
					thumbnail
				}
			}
			totalCount
			pageNumber
			pageSize
		}
	}
`;

export const SimpleContents = gql`
	query SimpleContents($searchQuery: SearchQuery!) {
		contents(searchQuery: $searchQuery) {
			data {
				id
				brand
				title
				externalId
				contentId
				subtitle
			}
			totalCount
			pageNumber
			pageSize
		}
	}
`;

export const GetCompany = gql`
	query GetCompany($id: ID, $slug: String, $domain: String) {
		company(id: $id, slug: $slug, domain: $domain) {
			id
			name
			logoUrl
			description
			city
			country
			postalCode
			phone
			email
			websites
			ubAccountCode
			address
			companyType
			links {
				name
				type
				url
				locales
			}
			fax
			companyPage {
				publishStatus
				descriptionHeader
				descriptionBody
				shortLink
				directLink
				slug
				isContactSectionHidden
				customTitle
			}
			departments {
				id
				translatedName
				logoUrl
				links {
					name
					type
					url
					locales
				}
				companyContacts {
					name
					email
					description
					translatedDescription
					phone
					avatarSrcUrl
					locales
				}
				companyPage {
					publishStatus
					descriptionHeader
					descriptionBody
					shortLink
					directLink
					slug
					isContactSectionHidden
					customTitle
				}
			}
		}
	}
`;

export const GetCompanyBasicData = gql`
	query GetCompanyBasicData($id: ID, $slug: String, $domain: String) {
		company(id: $id, slug: $slug, domain: $domain) {
			id
			name
			logoUrl
		}
	}
`;

export const MediathekCourses = gql`
	query MediathekCourses($searchQuery: ContentSearchInput!) {
		contentDocuments(searchQuery: $searchQuery) {
			data {
				brand
				subtitle
				contentId
				slug
				externalId
				startDateTime
				endDateTime
				city
				id
				locale
				contentType
				product {
					id
					name
					code
					brand
					specialityIconUrl
					iconPrimaryColor
					topics {
						id
						code
					}
				}
				willBeBroadcasted
				participationOption
				capacityStatus
				credits {
					participationType
					isApproved
					amount
					unit
				}
				specialities
				title
			}
			aggregations {
				buckets {
					key
					value
				}
				name
			}
			totalCount
		}
	}
`;

export const GetUserPaymentStatus = gql`
	query GetUserPaymentStatus($paymentReference: String!, $userId: ID!, $tenantId: ID! = "${env.TENANT_ID}") {
		userPaymentStatus(paymentReference: $paymentReference, userId: $userId, tenantId: $tenantId) {
			paymentStatus
		}
	}
`;

export const GetTenantPartnerCourses = gql`
	query GetTenantPartnerCourses($searchQuery: ContentSearchInput!) {
		contentDocuments(searchQuery: $searchQuery) {
			data {
				id
				contentType
				contentId
				brand
				startDateTime
				title
				subtitle
				slug
				externalId
				startDateTime
				endDateTime
				city
				locale
				contentType
				willBeBroadcasted
				participationOption
				capacityStatus
				specialities
				product {
					id
					name
					code
					brand
					specialityIconUrl
					iconPrimaryColor
					topics {
						id
						code
					}
				}
				credits {
					participationType
					isApproved
					amount
					unit
				}
			}
			totalCount
			pageNumber
			pageSize
		}
	}
`;
